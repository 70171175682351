import { createOrEditItem, deleteItem } from './form'
import { logger } from '../actions'
import { connectedApiService as api } from '../index'
import { showNotification } from './notifications'
import { SET_IS_PARTNER } from './types'
import { companyPartnerFormData } from 'models/partner'

export const acceptPartnerBooking = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `partners/${bookingId}`,
    })
  )

export const acceptOffer = (bookingId, supplierId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `dispatch/booking/${bookingId}/${supplierId}`,
      }
    )
  )
export const acceptOfferOp = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `partner/localSupplier/${bookingId}`,
    })
  )
export const acceptOfferVIP = (bookingId, supplierId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `dispatch/vip/${bookingId}/${supplierId}`,
      }
    )
  )
export const declineOffer = (values, bookingId, supplierId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/cancelDispatchPartner/decline/${bookingId}/${supplierId}`,
      isEdit: true,
    })
  )

export const declineOfferVIP = (
  values,
  bookingId,
  supplierId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/cancelDispatchPartner/decline/vip/${bookingId}/${supplierId}`,
      isEdit: true,
    })
  )

export const completeTrip = (values, bookingId, supplierId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/completeTrip/${bookingId}/${supplierId}`,
      isEdit: true,
    })
  )

export const supportEmail = (values, bookingId, supplierId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `booking/partnerSupport/${bookingId}/${supplierId}`,
      isEdit: true,
    })
  )

export const createChauffeur = (values, token, partnerType) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint:
        partnerType === 'rental'
          ? `external/agent/${token}`
          : partnerType === 'meetGreet'
          ? `external/greeter/${token}`
          : `external/chauffeur/${token}`,
    })
  )

export const editChauffeur = (values, chauffeurId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/chauffeur/edit/${chauffeurId}`,
    })
  )
export const deleteChauffeur = chauffeurId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        editRequest: 'delete',
        isEdit: true,
        endpoint: `external/chauffeur/edit/${chauffeurId}`,
      }
    )
  )
export const assignChauffeur = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/chauffeur/assign/${bookingId}`,
    })
  )
export const assignVehicle = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/car/assign/${bookingId}`,
    })
  )

export const assignGreeter = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/greeter/assign/${bookingId}`,
    })
  )
export const addPickUpInstructions = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/pickup/assign/${bookingId}`,
    })
  )

export const completeAssign = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/confirm/assign/${bookingId}`,
    })
  )

export const completeAssignGreeter = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/greeter/confirm/${bookingId}`,
    })
  )

export const assignCollectionAgent = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/collectionAgent/assign/${bookingId}`,
    })
  )

export const addCollectionInstructions = (
  values,
  bookingId
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/collectionInstructions/assign/${bookingId}`,
    })
  )

export const completeCollectionAssign = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/confirmCollection/assign/${bookingId}`,
    })
  )
export const createCar = (values, token, isRental) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: isRental
        ? `external/car-rentals/${token}`
        : `external/car/${token}`,
    })
  )

export const editCar = (values, carId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `external/car/edit/${carId}`,
    })
  )
export const deleteCar = (carId, make, model, supplierId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      { make: make, model: model, supplierId: supplierId },
      {
        editRequest: 'delete',
        isEdit: true,
        endpoint: `external/car/edit/${carId}`,
      }
    )
  )
export const editCompany = (values, companyId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      formatFormData: companyPartnerFormData,
      endpoint: `external/company/edit/${companyId}`,
    })
  )

export const generatePdfBoard = bookingId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        endpoint: `generatePdf/${bookingId}`,
      }
    )
  )

export const createPartner = (values, companyId) => async dispatch => {
  await dispatch(
    createOrEditItem(values, {
      endpoint: `partner/registration/createUser`,
    })
  )
  setTimeout(() => {
    window.location = 'https://rolzo.com/'
  }, 3500)
}
export const checkPartner = (values, companyId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `partner/checkUser/login`,
    })
  )
export const setPartnerPassword = (values, token) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `partner/registration/setpassword/${token}`,
    })
  )
export const verifyPartnerPassword = values => async dispatch =>
  await dispatch(
    createOrEditItem(
      { values },
      {
        endpoint: `partner/registration/setpassword/`,
      }
    )
  )

export const resetPartnerPassword = (token, password) => async dispatch => {
  try {
    await api.post(
      'password-reset',
      {
        token,
        password,
      },
      false
    )
    dispatch(
      showNotification({
        message: "You've successfully reset password",
      })
    )
    setTimeout(() => {
      window.location = '/partner/auth/login'
    }, 3000)
  } catch (error) {
    console.log(error)
    logger({
      fileName: 'partners',
      error: error,
    })
  }
}

export const bookingChangesPartner = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `bookingChanges/partner/${id}`,
    })
  )
export const updateChangesStatusPartner = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `bookingChanges/updateStatus/partner/${id}`,
    })
  )

export const getPartnerDetails = token => async dispatch => {
  try {
    const url = `external/partnerToken/${token}`
    const response = await api.get(url)
    dispatch({
      type: SET_IS_PARTNER,
      payload: {
        isPartner: response.data.isPartner,
        isDriver: response.data.isDriver,
        isChauffeur: response.data.isChauffeur,
        isCarRental: response.data.isCarRental,
        isMeetGreet: response.data.isMeetGreet,
        isLocalSupplier: response.data.isLocalSupplier,
        user: response.data.user,
        userId: response.data.userId,
        partnerName: response.data.company ? response.data.company.name : null,
        companyId: response.data.company ? response.data.company._id : null,
        showSwitchBanner: response.data.showSwitchBanner
          ? response.data.showSwitchBanner
          : false,
      },
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const storeFlightIcao = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `partner/storeFlightIcao/${bookingId}`,
    })
  )

export const onPartnerStatusChange = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `partner/${bookingId}/changeStatus`,
    })
  )

export const onPartnerStatusChangeVip = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `partner/${bookingId}/changeStatusVip`,
    })
  )

export const getOperativeSuppliers = (values = {}) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'get',
      endpoint: 'partner/localSupplier',
    })
  )
export const updateOpSupplier = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: 'partner/localSupplier',
      successMessage: 'Operative supplier was successfully updated',
    })
  )

export const deleteOpSupplier = bookingId => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `partner/localSupplier/${bookingId}`,
      successMessage: 'Operative supplier was successfully removed',
    })
  )
export const updateOutsourcedCharges = (
  values = {},
  bookingId,
  message
) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `partner/localSupplier/${bookingId}/charges`,
      successMessage: message,
    })
  )

export const updateServiceNotes = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `partner/localSupplier/${bookingId}/notes`,
      successMessage: 'Service notes were successfully updated',
    })
  )
export const updatePartnerBanner = token => async dispatch =>
  await dispatch(
    createOrEditItem(null, {
      isEdit: true,
      endpoint: `showBanner/partner/${token}`,
    })
  )
export const updateOutsourcedPrice = (values, bookingId) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `partner/localSupplier/${bookingId}/outsourcePrice`,
      successMessage: 'Outsourced price was successfully updated',
    })
  )
