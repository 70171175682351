import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter, useLocation, useHistory } from 'react-router-dom'
import LoadingSpinner from '../LoadingSpinner'
import { SmallDown } from '../Responsive/breakpoints'
import { fetchNotificationsList, getChatsList, logout } from '../../actions'
import { isMobile } from 'helpers'
const BottomMenuLink = ({
  title,
  icon,
  isSelected,
  existUnread,
  onClickHandle,
  showAlertBadge = false,
  counts = 0,
  isAdmin,
}) => {
  if (isAdmin) {
    return (
      <div className='bottom-nav-link' onClick={onClickHandle}>
        <div className='bottom-nav-img-wrapper'>
          <img className='bottom-nav-link-img' src={'/images/' + icon} alt='' />
          {showAlertBadge && counts > 0 ? (
            <div className='bottom-menu-unread-indicator' />
          ) : (
            ''
          )}
        </div>
        <div className={`${isSelected ? 'selected-nav' : ''}`}>{title}</div>
      </div>
    )
  } else {
    return (
      <div
        className={`bottom-nav-link ${isSelected ? 'active' : ''}`}
        onClick={onClickHandle}
      >
        <div
          className='bottom-nav-img-wrapper'
          style={
            title === 'Quotes'
              ? { paddingTop: '2.2px' }
              : title === 'Support'
              ? { paddingTop: '1px' }
              : {}
          }
        >
          <img
            className='bottom-nav-link-img'
            src={'/images/' + icon}
            alt=''
            style={
              title === 'Quotes'
                ? { height: '17px' }
                : title === 'Support'
                ? { height: '22px' }
                : {}
            }
          />
          {existUnread ? <div className='bottom-menu-unread-indicator' /> : ''}
        </div>
        <div className={`${isSelected ? 'selected-nav' : ''}`}>{title}</div>
      </div>
    )
  }
}

const BootMenuItem = ({
  title,
  path,
  icon,
  currentPath,
  childAllowedRoutes,
  existUnread,
  onClickHandle,
  showAlertBadge = false,
  counts = 0,
  isAdmin,
}) => {
  var isSelected = currentPath && currentPath.startsWith(path)
  if (!childAllowedRoutes) {
    return (
      <BottomMenuLink
        title={title}
        icon={icon}
        isSelected={isSelected}
        existUnread={existUnread}
        onClickHandle={onClickHandle}
        showAlertBadge={showAlertBadge}
        counts={counts}
        isAdmin={isAdmin}
      />
    )
  }

  return (
    <>
      {childAllowedRoutes
        .filter(({ hideInSidebar }) => !hideInSidebar)
        .map(child => (
          <div key={child.path}>
            <BottomMenuLink
              key={child.path}
              title={child.title}
              icon={isSelected ? child.iconActive : child.iconInactive}
              isSelected={isSelected}
              existUnread={existUnread}
              onClickHandle={onClickHandle}
              isAdmin={isAdmin ? true : false}
            />
          </div>
        ))}
    </>
  )
}

function BottomMenu({
  routes,
  companyId = null,
  // unreadMessages,
  notifications,
  chatsList,
  fetchNotificationsList,
  logout,
  getChatsList,
  operationsAlertCount,
  pendingBookingsCount,
  unreadChatsByAdminCount,
}) {
  let [loadingLocation, setLoadingLocation] = useState(null)
  const [bookingChat, setBookingChat] = useState()
  const [unreadMessages, setUnreadMessages] = useState(0)
  const history = useHistory()
  const isMobileBrowser = isMobile()

  useEffect(() => {
    if (loadingLocation) {
      history.push(loadingLocation)
    }
  }, [loadingLocation])

  useEffect(() => {
    const found = chatsList.find(chat => chat.type === 'chat_with_admin')
    if (found && found.messages && bookingChat !== found) {
      if (
        found &&
        bookingChat &&
        found.messages.length !== bookingChat.messages.length
      ) {
        setUnreadMessages(found.unreadByUserMessagesCount)
        setBookingChat(found)
      } else if (!bookingChat) {
        setUnreadMessages(found.unreadByUserMessagesCount)
        setBookingChat(found)
      }
    }
  }, [chatsList, setBookingChat])

  let isClient = companyId ? true : false
  let location = useLocation()

  var currentLocation = location.pathname
  if (loadingLocation != null) {
    currentLocation = loadingLocation
  }

  var existUnreadNotices = notifications && notifications > 0

  var isMoreSelected = currentLocation && currentLocation.startsWith('/more')
  var isNotificationSelected =
    currentLocation && currentLocation.startsWith('/notifications-client')
  var isHomeSelected = currentLocation && currentLocation.startsWith('/home')
  var isChatSelected =
    currentLocation && currentLocation.startsWith('/chat-client')
  var isBookingsSelected =
    currentLocation && currentLocation.startsWith('/booking')
  var isQuotesSelected =
    currentLocation && currentLocation.startsWith('/quotes')
  var isPaymentsSelected =
    currentLocation && currentLocation.startsWith('/payments')
  var isAccountSelected =
    currentLocation && currentLocation.startsWith('/settings')
  var isAdminChatSelected =
    currentLocation && currentLocation.startsWith('/support-admin')
  var isDashboardSelected =
    currentLocation && currentLocation.startsWith('/alerts')
  var isClientsSelected =
    currentLocation && currentLocation.startsWith('/clients')
  var isSalesBookingsSelected =
    currentLocation && currentLocation.startsWith('/sales-bookings')

  function clickMenu(path) {
    if (path === '/notifications-client') {
      fetchNotificationsList()
    } else if (path === '/chat-client') {
      getChatsList()
    }
    setLoadingLocation(path)
  }

  return (
    <>
      {loadingLocation && <LoadingSpinner />}
      {isClient ? (
        <SmallDown>
          <div className='sidebar-bottom-container'>
            {/* <BootMenuItem
              key='chat'
              icon={
                isChatSelected
                  ? 'icon_chat_active.png'
                  : 'icon_chat_inactive.png'
              }
              title='Support'
              isClient={isClient}
              existUnread={
                isChatSelected ? false : unreadMessages && unreadMessages > 0
              }
              onClickHandle={() => {
                clickMenu('/chat-client')
              }}
            /> */}

            <BootMenuItem
              key='home'
              icon={
                isHomeSelected
                  ? 'icons/icon-home-black.png'
                  : 'icons/icon-home-grey.png'
              }
              title='Home'
              path='/home'
              isClient={isClient}
              onClickHandle={() => {
                clickMenu('/home')
              }}
              currentPath={currentLocation}
            />
            <BootMenuItem
              key='quotes'
              icon={
                isQuotesSelected
                  ? 'icon-quote-active.png'
                  : 'icon-quote-inactive.png'
              }
              title='Quotes'
              path='/quotes'
              onClickHandle={() => {
                clickMenu('/quotes/main/my-quotes')
              }}
              currentPath={currentLocation}
            />
            <BootMenuItem
              key='booking'
              icon={
                isBookingsSelected
                  ? 'menu_bookings_active.png'
                  : 'menu_bookings_inactive.png'
              }
              title='Bookings'
              path='/booking'
              onClickHandle={() => {
                clickMenu('/booking/main/new-booking')
              }}
              currentPath={currentLocation}
            />
            <BootMenuItem
              key='payments'
              icon={
                isPaymentsSelected
                  ? 'menu_payments_active.png'
                  : 'menu_payments_inactive.png'
              }
              title='Payments'
              path='/payments'
              onClickHandle={() => {
                clickMenu('/payments/main/invoices')
              }}
              currentPath={currentLocation}
            />
            {/* <BottomMenuLink
              key='notification'
              icon={
                isNotificationSelected
                  ? 'icon_notification_active.png'
                  : 'icon_notification_inactive.png'
              }
              title='Notifications'
              existUnread={existUnreadNotices}
              onClickHandle={() => {
                clickMenu('/notifications-client')
              }}
            /> */}
            <BootMenuItem
              key='more'
              icon={
                isMoreSelected
                  ? 'icons/active-more-app-icon.svg'
                  : 'icons/inactive-more-app-icon.svg'
              }
              title='More'
              path='/more'
              onClickHandle={() => {
                clickMenu('/more')
              }}
              currentPath={currentLocation}
            />
          </div>
        </SmallDown>
      ) : (
        [
          isMobileBrowser && (
            <SmallDown>
              <div className='sidebar-bottom-container-admin'>
                <BootMenuItem
                  key='alerts'
                  icon={
                    isDashboardSelected
                      ? 'icon_notification_active.png'
                      : 'icon_notification_inactive.png'
                  }
                  isAdmin={true}
                  title='Alerts'
                  showAlertBadge={true}
                  counts={operationsAlertCount}
                  onClickHandle={() => {
                    clickMenu('/alerts-ops')
                  }}
                />
                <BootMenuItem
                  key='chat'
                  icon={
                    isAdminChatSelected
                      ? 'icon_chat_active.png'
                      : 'icon_chat_inactive.png'
                  }
                  isAdmin={true}
                  title='Chat'
                  showAlertBadge={true}
                  counts={unreadChatsByAdminCount}
                  onClickHandle={() => {
                    clickMenu('/support-admin')
                  }}
                />
                <BootMenuItem
                  key='bookings'
                  icon={
                    isSalesBookingsSelected
                      ? 'menu_bookings_active.png'
                      : 'menu_bookings_inactive.png'
                  }
                  title='Bookings'
                  isAdmin={true}
                  showAlertBadge={true}
                  counts={pendingBookingsCount}
                  onClickHandle={() => {
                    clickMenu('/sales-bookings')
                  }}
                />
                <BootMenuItem
                  key='client'
                  icon={
                    isClientsSelected
                      ? 'menu_account_active.png'
                      : 'menu_account_inactive.png'
                  }
                  title='Clients'
                  isAdmin={true}
                  onClickHandle={() => {
                    clickMenu('/clients')
                  }}
                />
                <BootMenuItem
                  key='logout'
                  icon={'menu_logout.png'}
                  title='Logout'
                  isAdmin={true}
                  onClickHandle={logout}
                />
              </div>
            </SmallDown>
          ),
        ]
      )}
    </>
  )
}
const getUnreadMsgLength = appWebsocket => {
  if (appWebsocket.chatsList.length) {
    const found = appWebsocket.chatsList.find(
      chat => chat.type === 'chat_with_admin'
    )
    return found ? found.unreadByUserMessagesCount : 0
  }
}

const getUnReadNotificationsLength = appWebsocket => {
  if (appWebsocket.notificationsList && appWebsocket.notificationsList.length) {
    const found = appWebsocket.notificationsList.filter(
      notification => notification.readStatus === false
    )
    return found.length
  } else {
    return 0
  }
}

const mapStateToProps = ({ auth, appWebsocket } = {}) => ({
  companyId: auth.companyId,
  chatsList: appWebsocket.chatsList,
  unreadMessages: getUnreadMsgLength(appWebsocket),
  notifications: getUnReadNotificationsLength(appWebsocket),
  operationsAlertCount: appWebsocket.operationsAlertCount,
  pendingBookingsCount: appWebsocket.pendingBookingsCount,
  unreadChatsByAdminCount: appWebsocket.unreadChatsByAdminCount,
})

export default withRouter(
  connect(mapStateToProps, {
    fetchNotificationsList,
    getChatsList,
    logout,
  })(BottomMenu)
)
