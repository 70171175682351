import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { useLocation, withRouter, Link } from 'react-router-dom'
import {
  getLabelFromValue,
  getFormattedPrice,
  numberWithCommas,
  isMobile,
} from '../helpers'
import {
  defaultBookingServicePartner,
  defaultCurrencies,
  partnerHostLive,
  partnerHostStaging,
} from '../constants'
import { Button, Badge } from 'react-bootstrap'
import { acceptPartnerBooking } from '../actions/partners'
import DataTable from './DataTable'
import NoData from './NoData'
import _ from 'lodash'
const Host = window.location.hostname
const apiHost = process.env.REACT_APP_APIHOST

const isMobileBrowser = isMobile()
const DriverBookingTable = ({
  data,
  reloadList,
  acceptPartnerBooking,
  setIsLoading,
  history,
  token,
  isPlannedTab,
  isNewBookingTab,
  isCompletedTab,
  isBookingsLoading = false,
  isPartner,
  isCarRental,
  isLocalSupplier,
}) => {
  let location = useLocation()
  let currentLocation = location.pathname

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  const isNewBookings =
    currentLocation && currentLocation.endsWith('/new-bookings')
  const isPlanned = currentLocation && currentLocation.endsWith('/planned')
  const isCompleted = currentLocation && currentLocation.endsWith('/completed')

  const title = isNewBookingTab
    ? !isPartner
      ? 'No new bookings'
      : 'No new offers'
    : isCompletedTab
    ? 'No completed bookings'
    : isPlannedTab && 'No planned bookings'
  const description = isNewBookingTab
    ? !isPartner
      ? 'We will notify you when we have a new booking'
      : 'We will notify you when we have a new offer'
    : isCompletedTab
    ? `You don't have any completed bookings yet`
    : isPlannedTab && `You don't have any planned bookings yet`

  const onAcceptBooking = async bookingId => {
    try {
      if (bookingId) {
        setIsLoading(true)
        const geo = `00.00-00.00`
        let obj = {
          action: 'accepted',
          geoLocation: geo,
          isLiveLocation: false,
        }
        const res = await acceptPartnerBooking(obj, bookingId)
        if (res) setIsLoading(false)
        reloadList()
        // navigator.geolocation.getCurrentPosition(
        //   async function(pos) {
        //     const geo = `${pos.coords.latitude},${pos.coords.longitude}`
        //     let obj = {
        //       action: 'accepted',
        //       geoLocation: geo,
        //       isLiveLocation: true,
        //     }
        //     const res = await acceptPartnerBooking(obj, bookingId)
        //     if (res) setIsLoading(false)
        //     reloadList()
        //   },
        //   async function(error) {
        //     if (error.code === error.PERMISSION_DENIED) {
        //       const geo = `51.4933,-0.1684`
        //       let obj = {
        //         action: 'accepted',
        //         geoLocation: geo,
        //         isLiveLocation: false,
        //       }
        //       const res = await acceptPartnerBooking(obj, bookingId)
        //       if (res) setIsLoading(false)
        //       reloadList()
        //     }
        //   }
        // )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onViewBooking = async bookingId => {
    try {
      if (bookingId) {
        history.push(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/${
            partnerType === 'meetGreet' ? 'vip/' : ''
          }${token}/${bookingId}`
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const rowClickHandler = ({ _id }) => () => {
    try {
      if (_id) {
        history.push(
          `${
            Host === partnerHostLive || Host === partnerHostStaging
              ? '/'
              : '/partner/'
          }driver/app/details/${
            partnerType === 'meetGreet' ? 'vip/' : ''
          }${token}/${_id}`
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
  const stopReload = evt => {
    evt.stopPropagation()
  }

  // light background: #8B959E0D;
  // dark background: #8B959E33;
  const columns = [
    {
      title: 'Booking #',
      selector: 'number',
      titleClassName: 'text-left',
      colClassName: 'text-left',
      renderCol: ({ _id, number, isLocalSupplier }) => {
        return (
          <>
            <span
              className='partner-field-extended'
              style={{
                marginLeft: '0.5rem',
              }}
            >
              {isMobileBrowser ? (
                `${number}`
              ) : (
                <Link
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/details/${
                    partnerType === 'meetGreet' ? 'vip/' : ''
                  }${token}/${_id}`}
                  target='_blank'
                  className='text-brown pointer-link'
                  onClick={evt => stopReload(evt)}
                >
                  {number}
                </Link>
              )}
              {isLocalSupplier ? (
                <img
                  style={{ height: '19px', marginLeft: '0.8em' }}
                  src='/images/icons/partner/icon-location.png'
                  alt=''
                />
              ) : (
                <></>
              )}
            </span>
          </>
        )
      },
    },
    {
      title: 'Date and time',
      selector: 'pickUpDate',
      titleClassName: 'text-left padding-left-partner',
      colClassName: 'text-left',
      renderCol: ({ pickUpDate }) => {
        return (
          <>
            <span className='partner-field-extended'>
              {/* {moment.utc(pickUpDate).format('Do MMM YY, LT')} */}
              {moment.utc(pickUpDate).format('Do MMM YY, HH:mm')}
            </span>
          </>
        )
      },
    },
    {
      title: 'Service',
      selector: 'service',
      titleClassName: 'text-left padding-left-partner',
      colClassName: 'text-left',
      renderCol: ({ type }) => {
        return (
          <>
            <span className='partner-field-extended'>
              {getLabelFromValue(defaultBookingServicePartner, type)}
            </span>
          </>
        )
      },
    },
    ...(partnerType !== 'meetGreet'
      ? [
          {
            title: 'Vehicle',
            selector: 'vehicleBrand',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({ vehicleModel, vehicleBrand }) => {
              return (
                <>
                  <span className='noWrap-whiteSpace'>
                    {vehicleBrand} {vehicleModel}
                  </span>
                </>
              )
            },
          },
        ]
      : []),
    ...(partnerType === 'meetGreet'
      ? [
          {
            title: 'Airport',
            selector: 'airport',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({ airport }) => {
              return (
                <>
                  <span className='partner-ellipsis-field'>{airport}</span>
                </>
              )
            },
          },
        ]
      : []),
    {
      title: 'City',
      selector: 'city',
      titleClassName: 'text-left padding-left-partner',
      colClassName: 'text-left',
      renderCol: ({ pickUpLocation }) => {
        return (
          <>
            <span className='partner-ellipsis-field'>
              {pickUpLocation && pickUpLocation.city}
            </span>
          </>
        )
      },
    },
    {
      title: 'Price',
      selector: 'dispatchPrice',
      titleClassName: 'text-left padding-left-partner',
      colClassName: 'text-left',
      renderCol: ({ dispatchPrice, dispatchCurrency }) => {
        return (
          <>
            <span className='partner-field-extended'>
              {dispatchPrice && dispatchCurrency ? (
                <>
                  {numberWithCommas(getFormattedPrice(dispatchPrice))}{' '}
                  {getLabelFromValue(defaultCurrencies, dispatchCurrency)}{' '}
                </>
              ) : (
                <>
                  {numberWithCommas(getFormattedPrice(dispatchPrice))}{' '}
                  {getLabelFromValue(defaultCurrencies, dispatchCurrency)}{' '}
                </>
              )}
            </span>
          </>
        )
      },
    },
    {
      title: 'Status',
      selector: 'status',
      titleClassName: 'text-left padding-left-partner',
      colClassName: 'text-left',
      renderCol: ({ status, dispatchStatus, isOperativeBranch }) => {
        return isCompleted ? (
          <Badge
            href='#'
            variant={
              status === 'cancellation_accepted_by_agent' ||
              status === 'cancelled_by_agent'
                ? `cancelled-dispatch badge-outlined`
                : 'completed-dispatch badge-outlined'
            }
          >
            {status === 'cancellation_accepted_by_agent' ||
            status === 'cancelled_by_agent'
              ? 'CANCELLED'
              : 'COMPLETED'}
          </Badge>
        ) : isPlanned ? (
          <Badge
            href='#'
            variant={
              status === 'cancellation_accepted_by_agent' ||
              status === 'cancelled_by_agent'
                ? `cancelled-dispatch badge-outlined`
                : dispatchStatus === 'change_request'
                ? 'change-request-dispatch badge-outlined'
                : dispatchStatus === 'declined'
                ? 'expired-dispatch badge-outlined'
                : dispatchStatus === 'outsourced'
                ? `outsourced-dispatch badge-outlined`
                : 'accepted-dispatch badge-outlined'
            }
          >
            {status === 'cancellation_accepted_by_agent' ||
            status === 'cancelled_by_agent'
              ? 'CANCELLED'
              : dispatchStatus === 'change_request'
              ? 'CHANGE REQUEST'
              : dispatchStatus === 'declined'
              ? 'DECLINED'
              : dispatchStatus === 'outsourced'
              ? 'OUTSOURCED'
              : 'ACCEPTED'}
          </Badge>
        ) : (
          <>
            {isOperativeBranch && dispatchStatus === 'outsourced' ? (
              <Badge href='#' variant={`outsourced-dispatch badge-outlined`}>
                {'OUTSOURCED'}
              </Badge>
            ) : (
              <Badge href='#' variant={`new-booking badge-outlined`}>
                {'PENDING'}
              </Badge>
            )}
          </>
        )
      },
    },
    ...(isLocalSupplier && (isPlannedTab || isCompletedTab)
      ? [
          {
            title: 'Partner',
            colClassName: 'noWrap-whiteSpace',
            renderCol: ({ partner, dispatchStatus, outsourcedSuppliers }) => {
              return (
                <>
                  {dispatchStatus === 'accepted' ||
                  dispatchStatus === 'completed' ||
                  dispatchStatus === 'change_request' ? (
                    <span style={{ marginLeft: '0.55rem' }}>{partner}</span>
                  ) : (
                    <>
                      {(dispatchStatus === 'dispatched' ||
                        dispatchStatus === 'outsourced') &&
                      outsourcedSuppliers &&
                      outsourcedSuppliers.length ? (
                        <span
                          className={'dispatch-number'}
                          style={{ marginLeft: '1.5rem' }}
                        >
                          {outsourcedSuppliers.length}
                        </span>
                      ) : (
                        <span>{'—'}</span>
                      )}
                    </>
                  )}
                </>
              )
            },
          },
        ]
      : []),
    ...(!isNewBookingTab && partnerType !== 'meetGreet'
      ? [
          {
            title: partnerType === 'rental' ? 'Agent' : 'Chauffeur',
            selector: 'driver',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({ driver, driverTemp }) => {
              return (
                <>
                  <Badge
                    href='#'
                    variant={
                      driver || driverTemp
                        ? 'accepted-dispatch badge-outlined'
                        : 'cancelled-dispatch badge-outlined'
                    }
                  >
                    {driver || driverTemp ? 'YES' : 'NO'}
                  </Badge>
                </>
              )
            },
          },
          {
            title: 'Changes',
            selector: 'bookingChanges',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({ bookingChanges }) => {
              return (
                <div style={{ display: 'flex', justifyContent: 'start' }}>
                  {bookingChanges.received > 0 ? (
                    <span className='received-partner-number-tab ml-0'>
                      {bookingChanges.received}
                    </span>
                  ) : bookingChanges.accepted > 0 ? (
                    <span className='accepted-partner-number-tab ml-0'>
                      {bookingChanges.accepted}
                    </span>
                  ) : (
                    <span>{'—'}</span>
                  )}
                </div>
              )
            },
          },
        ]
      : []),
    ...(!isNewBookingTab && partnerType === 'meetGreet'
      ? [
          {
            title: 'Greeter',
            selector: 'greeter',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({ greeter, greeterTemp }) => {
              return (
                <>
                  <Badge
                    href='#'
                    variant={
                      greeter || greeterTemp
                        ? 'accepted-dispatch badge-outlined'
                        : 'cancelled-dispatch badge-outlined'
                    }
                  >
                    {greeter || greeterTemp ? 'YES' : 'NO'}
                  </Badge>
                </>
              )
            },
          },
          {
            title: 'Changes',
            selector: 'bookingChanges',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({ bookingChanges }) => {
              return (
                <div style={{ display: 'flex', justifyContent: 'start' }}>
                  {bookingChanges.received > 0 ? (
                    <span className='received-partner-number-tab ml-0'>
                      {bookingChanges.received}
                    </span>
                  ) : bookingChanges.accepted > 0 ? (
                    <span className='accepted-partner-number-tab ml-0'>
                      {bookingChanges.accepted}
                    </span>
                  ) : (
                    <span>{'—'}</span>
                  )}
                </div>
              )
            },
          },
        ]
      : []),
    ...(isCompletedTab
      ? [
          {
            title: 'Extras',
            selector: 'partnerEarningCount',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({
              partnerEarningReceivedCount,
              partnerEarningSentCount,
            }) => {
              return (
                <div style={{ display: 'flex', justifyContent: 'start' }}>
                  {partnerEarningReceivedCount ? (
                    <span className='accepted-partner-number-tab ml-0'>
                      {partnerEarningReceivedCount}
                    </span>
                  ) : partnerEarningSentCount ? (
                    <span className='received-partner-number-tab ml-0'>
                      {partnerEarningSentCount}
                    </span>
                  ) : (
                    <span>{'—'}</span>
                  )}
                </div>
              )
            },
          },
        ]
      : []),
    ...(isCompletedTab
      ? [
          {
            title: 'Notes',
            selector: 'notes',
            titleClassName: 'text-left padding-left-partner',
            colClassName: 'text-left',
            renderCol: ({ notesCount }) => {
              return (
                <div style={{ display: 'flex', justifyContent: 'start' }}>
                  {notesCount ? (
                    <span className='notes-partner-number ml-0'>
                      {notesCount}
                    </span>
                  ) : (
                    <span>{'—'}</span>
                  )}
                </div>
              )
            },
          },
        ]
      : []),
    ...(isMobileBrowser
      ? [
          {
            title: 'Action',
            titleClassName: 'text-center',
            colClassName: 'partner-action',
            renderCol: ({ _id, isForDriver, status }) => {
              return (
                <>
                  {isForDriver
                    ? [
                        isNewBookings &&
                        status !== 'cancellation_accepted_by_agent' &&
                        status !== 'cancelled_by_agent' ? (
                          <Button
                            className='partner-btn-desktop-secondary select-vehicle'
                            variant='brown'
                            onClick={() => {
                              onAcceptBooking(_id)
                            }}
                          >
                            Accept
                          </Button>
                        ) : (
                          <Button
                            className='partner-btn-desktop-secondary select-vehicle'
                            variant='brown'
                            onClick={() => {
                              onViewBooking(_id)
                            }}
                          >
                            View
                          </Button>
                        ),
                      ]
                    : [
                        <Button
                          className='partner-btn-desktop-secondary select-vehicle'
                          variant='brown'
                          onClick={() => {
                            onViewBooking(_id)
                          }}
                        >
                          View
                        </Button>,
                      ]}
                </>
              )
            },
          },
        ]
      : []),
  ]
  return (
    <>
      {data ? (
        <DataTable
          list={data}
          columns={columns}
          className='light-theme-table-partner'
          onRowClick={isMobileBrowser ? () => {} : rowClickHandler}
          renderNoData={() => (
            <>
              {!isBookingsLoading && (
                <NoData
                  image='/images/icons/icon-bookings-grey.png'
                  width={25}
                  title={title}
                  description={description}
                  isPartner
                />
              )}
            </>
          )}
        />
      ) : (
        <> </>
      )}
    </>
  )
}
const mapStateToProps = ({
  auth: { isPartner = null, isDriver = null, isCarRental = null },
}) => ({
  isPartner,
  isDriver,
  isCarRental,
})

export default withRouter(
  connect(mapStateToProps, { acceptPartnerBooking })(DriverBookingTable)
)
